// Import Angular Material theming utilities
@use '@angular/material' as mat;

// Define your custom color palette
$primary-color: mat.define-palette(mat.$indigo-palette, 500);
$accent-color: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn-color: mat.define-palette(mat.$red-palette);

// Define your custom theme using primary, accent, and warn palettes
$custom-theme: mat.define-light-theme((
  color: (
    primary: $primary-color,
    accent: $accent-color,
    warn: $warn-color,
  ),
  // You can customize density here if needed
  density: 0, // Optional, set density for compact or spacious layouts
));

// Apply the custom theme to your application
@include mat.core(); // Required core styles for Angular Material components
@include mat.all-component-themes($custom-theme); // Apply theme to all components

// Typography improvements and additional custom styles
body {
  font-family: 'Roboto, Arial, sans-serif'; // Set your global font family
  margin: 0;
  background-color: mat.get-color-from-palette(mat.$grey-palette, 50); // Light background color
}

// Customize Material component styles
.mat-card {
  @include mat.elevation(3); // Custom elevation for cards
  border-radius: 8px; // Add rounded corners for a modern look
}

// Add padding to the body for a neat layout
body, html {
  margin: 0;
  padding: 0;  // Remove the padding here
  height: 100%;
  width: 100%;
}

// Customize button styles for a modern look
.mat-raised-button {
  border-radius: 24px; // Add rounded corners to buttons
}

// Customize app-wide form field appearance
.mat-form-field {
  border-radius: 4px;
  border-color: mat.get-color-from-palette($primary-color);
}

// Custom toolbar styling
.mat-toolbar {
  background-color: mat.get-color-from-palette($primary-color);
  color: mat.get-color-from-palette(mat.$grey-palette, 50); // Light text color for toolbar
}

@import 'ngx-toastr/toastr';
